:root {
  --sitecolor: #2c5691;
  --textcolor: #000;
  --titlecolor: #000;
  --linkcolor: #000;
  --linkcolor-hov: #90814e;
  --headerHeight: 140px;
  --gridGap: 25px;
  --easing: cubic-bezier(0.76, 0, 0.24, 1);
}
.button {
  font-size: clamp(0.9375rem, 0vw + 0.9375rem, 0.9375rem);
  line-height: clamp(1.5rem, 0vw + 1.5rem, 1.5rem);
  font-family: inherit;
  cursor: pointer;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  border: none;
  display: inline-block;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.01em;
  padding-right: 20px;
  text-decoration: none;
  color: var(--linkcolor);
  position: relative;
}
.button:after {
  content: '';
  position: absolute;
  top: 5px;
  right: 0;
  width: 20px;
  height: 10px;
  mask-size: 11px 8px;
  mask-position: 100% 50%;
  mask-repeat: no-repeat;
  mask-image: url(/images/arrow-right-black.svg);
  background-color: var(--linkcolor);
  transition: all 0.4s var(--easing);
}
.button:hover,
.button:focus {
  color: var(--linkcolor-hov);
}
.button:hover:after,
.button:focus:after {
  background-color: var(--linkcolor-hov);
}
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.area--one {
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  gap: 0 30px;
}
.area--one .unit {
  grid-column: span 6;
}
.area--one .unitOne--1-2 {
  grid-column: span 3;
}
.area--one .unitOne--1-3 {
  grid-column: span 3;
}
/*# sourceMappingURL=./screen-medium.css.map */